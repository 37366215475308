export default {
    data: function() {
        var self = this;
        return {
            htSettingsDirecciones: {
                beforeChange: function(changes, source) {
                    var h = this;
                    for (var i = changes.length - 1; i >= 0; i--) {
                        var change = changes[i];
                        var row = change[0];
                        var prop = change[1];
                        var oldVal = change[2];
                        var newVal = change[3];
                        if (prop == "predet" && source != "auto") {
                            newVal = parseInt(newVal) || 0;
                            if (newVal) {
                                window.$.each(h.getDataAtProp("predet"), function(ind, item) {
                                    if (ind == h.getDataAtProp("predet").length - 1) return;
                                    h.setDataAtRowProp(ind, "predet", 0, "auto");
                                });
                            } else {
                                changes[i][3] = 1;
                            }
                        }
                        if (prop != "predet") {
                            var haypredet = false;
                            window.$.each(h.getDataAtProp("predet"), function(i, item) {
                                if (parseInt(item) || 0) {
                                    haypredet = true;
                                    return;
                                }
                            });
                            if (!haypredet) {
                                h.setDataAtRowProp(row, "predet", 1, "auto");
                            }
                        }
                    }
                }
            }
        }
    },
    mounted() {
        var self = this;
        let hd = self.$refs.direcciones.field.hotInstance;
        let arrProps = [
            'responsable',
            'direccion_comercial',
            'direccion_comercial1',
            'cp_comercial',
            'poblacion_comercial',
            'provincia_comercial_id',
            'pais_comercial_id',
            'telefono_comercial',
            'telefono_comercial1',
            'movil_comercial',
            'fax_comercial',
            'email_comercial'
        ];
        self.$on("change", function(prop, source) {
            if (self.mode != "new" && self.mode != "edit") return;
            if (!arrProps.includes(prop)) return;
            let prop2 = prop.replace('_comercial', '');
            let dirs = self.formData.direcciones;
            if (dirs && dirs[0] && dirs[0][prop2]) return;
            hd.setDataAtRowProp(0, prop2, self.formData[prop], 'auto');
        });


    }
};